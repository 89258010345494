import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Resume from './Resume'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `16px`,
    overflow: `hidden`,
    position: `relative`,
    boxShadow: 'rgb(28 33 54 / 10%) 0px 5px 25px',
    maxWidth: 850
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  }
}

const AboutPage = (props) => {
  const data = useStaticQuery(aboutMeQuery)
  const image = getImage(data.avatar)

  return (
    <Layout {...props}>
      <Seo title='About' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='About Me'
            subheader='The future belongs to those who believe in the beauty of their dreams.'
          />
          {/* <Divider /> */}

          <Box sx={styles.imageWrapper}>
            <Img image={image} alt='About Me Image' />
            <Button sx={styles.button} as={Link} to='/contact'>Contact Me</Button>
          </Box>

          {/* <Divider /> */}
          <Resume />
          {/* <Flex sx={styles.grid}> */}
          {/*   <Box sx={styles.column}> */}
          {/*     <Section title='My Story'> */}
          {/*       <Text variant='p'> */}
          {/*         I started this blog in a particularly challenging time, */}
          {/*         when my business was close to the edge.­ I was never really fond of putting myself in the middle, */}
          {/*         not even to think of marketing myself, but like they say, hard times call for hard measures. */}
          {/*         So here I found myself, writing this about section, trying to save my business somehow. */}
          {/*       </Text> */}
          {/*       <Text variant='p'> */}
          {/*         Everything started about 5 years ago. As a tech-savvy and curious young student, */}
          {/*         I started my bachelor's degree in Mechatronics in 2015 at the University Erlangen. */}
          {/*         Along the way, I was able to gain a little bit of insights into the “big players” at Siemens and Schaeffler. */}
          {/*       </Text> */}
          {/*       <Text variant='p'> */}
          {/*         During my time as a student, I have found myself interested in topics ranging from psychology to astronomy */}
          {/*         that change society and the lives of every individual. The unbelievable wealth of information from great people, */}
          {/*         which is available to us in this day and age largely free of charge, has always gripped me and I am thrilled by it */}
          {/*         every day over and over again. I am firmly convinced that everyone can take his life into his own hands and achieve */}
          {/*         what he wants, if he just goes through with it. */}
          {/*       </Text> */}
          {/*       <Text variant='p'> */}
          {/*         So, my beliefs and perceptions on different things have changed a lot during my studies. */}
          {/*         However, there was one common denominator. Life for me as an individual is always better when everyone else is better, */}
          {/*         too. From this understanding, step by step a mission was formed to change the world gradually a little bit each day. */}
          {/*       </Text> */}
          {/*       {/* */}
          {/*       <Text variant='p'> */}
          {/*         <ul> */}
          {/*           <li>Cotidieque reformidans</li> */}
          {/*           <li>Mucius sensibus</li> */}
          {/*           <li>Sit primis iudicabit</li> */}
          {/*         </ul> */}
          {/*       </Text> */}
          {/*      */}
          {/*       <Text variant='p'> */}
          {/*         At the beginning of 2019 it was finally time. With the mission to offer society a healthier, */}
          {/*         broader in perspective and above all more fulfilling life and to accompany each individual on his own path, */}
          {/*         with my roommate and best friend I founded LYTE Vapes. */}
          {/*       </Text> */}
          {/**/}
          {/*       <Text variant='p'> */}
          {/*         At LYTE Vapes, we offer high quality electronic Vaporizer for the application of Aromatherapy. */}
          {/*         After almost two years of intense development and working like hell, we did manage to develop our first product. */}
          {/*         All of this at the same time as studying and working at a part time job. To this day, */}
          {/*         I have no idea how this was possible, but we did it somehow. In January 2020 we finally launched our first product, */}
          {/*         the PATH Vaporizer, and then… */}
          {/*       </Text> */}
          {/*       <Text variant='p'> */}
          {/*         ...Nothing happened! */}
          {/*       </Text> */}
          {/*       <Text variant='p'> */}
          {/*         We got restricted from almost all advertising Platforms because the business we are in is related to the */}
          {/*         tobacco industry and our marketing plan for selling online failed. What do you do when something fails? Right, */}
          {/*         you try something else. Said and done, we went out to all CBD and tobacco stores we could find. As you can imagine */}
          {/*         selling for the first time with no experience and no one to guide you is quite a challenge, but we got it going somehow. */}
          {/*         Then suddenly the new COVID-19 virus arose only 3 months after our product launch and we were back to online. */}
          {/*         At this point we figured out some ways to market our product online and our sales were growing again until the end of 2020. */}
          {/*         We improved our product in the meantime and launched an incrementally improved Version of the PATH Vaporizer and produced a */}
          {/*         substantial number of devices. */}
          {/*       </Text> */}
          {/*       <Text variant='p'> */}
          {/*         Then in 2021 the new advertising laws in Germany took effect and we suddenly could not do any advertising anymore. */}
          {/*         Because of this we shifted away from paid advertising and fueled the word-of-mouth marketing, by delivering outstanding */}
          {/*         service and giving our customers more than what they paid for. Surprisingly this strategy worked out pretty well and we */}
          {/*         are continually growing right now, helping more and more people to live a healthier and more fulfilling life each and every day. */}
          {/*       </Text> */}
          {/*       <Text variant='p'> */}
          {/*         This blog is one try to get a crisp on people like you, awesome people, who are searching for new ways to improve */}
          {/*         themselves and the world. We put so much work and passion and love into everything we do and I want to show how you */}
          {/*         can too. I always wanted to put out the things I learned so far and I wish I knew way earlier in life. There are a */}
          {/*         lot of great books out there, where you can basically get the knowledge of a person's whole life in just a few hours. */}
          {/*         Go check out my recommended book list if you don’t know where to start. */}
          {/*         Also check out our company homepage <a href="https://lytevapes.com">www.lytevapes.com</a>. We have a great product and */}
          {/*         to be honest without the vaporizer I literally would not be in the point in my life were I am right now. I am amazed */}
          {/*         every time again, how a little shift in perspective, can change your whole life for the better. With this blog I want */}
          {/*         to give something back to humanity and finally make this world a better place. */}
          {/*       </Text> */}
          {/*       <Text variant='p'> */}
          {/*         It's time for you to follow your path too. Join us on our mission for a more fulfilling */}
          {/*         life and let's find out what's possible */}
          {/*       </Text> */}
          {/*       <Text variant='p' style={{ marginTop: 50, marginBottom: 25 }}> */}
          {/*         Yours sincerely, */}
          {/*       </Text> */}
          {/*       <Text variant='p' style={{ marginBottom: 50 }}> */}
          {/*         Fabian Jost */}
          {/*       </Text> */}
          {/*     </Section> */}
          {/*   </Box> */}
          {/*   <Box sx={styles.column}> */}
          {/*     <Section title='LYTE Vapes'> */}
          {/*       <Card variant='paper'> */}
          {/*         AT LYTE Vapes we believe that everyone should live a healthy and fulfilling life. */}
          {/*         Our Mission is to support you in achieving that. */}
          {/*       </Card> */}
          {/*     </Section> */}
          {/*     <Divider /> */}
          {/*     <Section title='Engineering'> */}
          {/*       <Card variant='paper'> */}
          {/*         I have always had a passion for engineering and the technology behind digital products. */}
          {/*         Because of that I finished my Bachelors degree in Mechatronics at the Friedrich-Alexander-University */}
          {/*         Erlangen-Nuremberg in Germany. */}
          {/*       </Card> */}
          {/*     </Section> */}
          {/*     <Divider /> */}
          {/*     <Section title='Psychology'> */}
          {/*       <Card variant='paper'> */}
          {/*         As a student and by reading more and more books, I developed a passion for psychology. */}
          {/*         How the human mind works has fascinated me ever since and it is always striking to find out something new. */}
          {/*       </Card> */}
          {/*     </Section> */}
          {/*   </Box> */}
          {/* </Flex> */}
        </Main>
      </Stack>
    </Layout>
  )
}

const aboutMeQuery = graphql`
  query AboutMeQuery {
    avatar: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1080
          height: 719
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`

export default AboutPage;
